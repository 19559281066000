import React from 'react';
import Navbar from '../components/Navbar';
import FarmShareBG from '../assets/FarmshareBG.jpg';
import ContentBox from '../components/ContentBox';

const FarmsharePage = () => {
  return (
    <div className="bg-sky-200">
      <Navbar
        src={FarmShareBG}
        pageName={`Farmshare`}
        pageText={
          'USLTRCD has partnered with Cal Poly to' +
          ' design and construct biochar kilns, compost spreaders, and other equipment.' +
          '  See our current stock of equipment below.'
        }
      />
      <ContentBox
        title="The Farmshare page is currently under construction!"
        content={
          <p>
            The equipment reservation system will appear here once development is complete. Until then, please contact the <a href="https://www.us-ltrcd.org/contact-us" target="partner"
            className="text-lime-700 hover:text-emerald-700 hover:underline">Upper Salinas-Las Tablas RCD</a> if you require a compost spreader or biochar kiln. 
          </p>

        }
        bgColor={'#FFFFFF'}
      />
    </div>
  );
};

export default FarmsharePage;
