import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function EventCard({
  title,
  subheader,
  altImageText,
  image,
  dateText,
  timeText,
  location,
  mainText,
  signUpURL,
  learnMoreURL,
}) {
  return (
    <Card
      raised={true}
      sx={{ minWidth: 300, maxWidth: 345 }}
      style={{ marginRight: '1em', marginLeft: '1em' }}
    >
      <CardHeader title={title} subheader={subheader} />

      <CardMedia
        component="img"
        alt={altImageText}
        height="140"
        image={image}
      />
      <CardContent>
        <Typography
          variant="font-bold"
          className="text-center"
          color="blue-grey"
        >
          <div className="flex justify-between">
            <p className="text-left">{dateText}</p>
            <p className="text-right">{timeText}</p>
          </div>
          {location}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {mainText}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" href={signUpURL}>
          Sign Up
        </Button>
        <Button size="small" href={learnMoreURL}>
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
}
