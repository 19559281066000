import React, { Fragment, useEffect } from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import authActions from './redux/actions/auth.actions';
import { useDispatch } from 'react-redux';
import Navbar from './components/Navbar';
import Login from './pages/Login';
import Signup from './pages/Signup';
import ErrorPage from './pages/ErrorPage';
import HomePage from './pages/HomePage';
import Footer from './components/Footer';
import FarmsharePage from './pages/FarmsharePage';
import FarmsharePageInProgress from './pages/FarmsharePageInProgress';
import AchievementsPage from './pages/AchievementsPage';
import AboutUsPage from './pages/AboutUsPage';
import RCDPage from './pages/RCDPage';
import ContactUsPage from './pages/ContactUsPage';
import EventPage from './pages/EventPage';
import ResourcesPage from './pages/ResourcesPage';
import DetailEquipmentPage from './pages/DetailEquipmentPage';
import HomePageBG from './assets/HomepageBG.jpg';

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authActions.getCurrentUser());
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/* <Route path="/login" element={<Login />} /> */}
        {/* <Route path="/signup" element={<Signup />} /> */}
        <Route path="/events" element={<EventPage />} />
        <Route path="/farmshare" element={<FarmsharePage />} />
        <Route path="/farmshareIP" element={<FarmsharePageInProgress />} />
        <Route
          path="/farmshare/:equipmentId"
          element={<DetailEquipmentPage />}
        />
        <Route path="/achievements" element={<AchievementsPage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/rcd" element={<RCDPage />} />
        <Route path="/resources" element={<ResourcesPage />} />
        <Route
          path="*"
          element={
            <Fragment>
              <Navbar src={HomePageBG} pageName={``} />
              <ErrorPage />
            </Fragment>
          }
        />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
