import React from 'react';

const PictureContentRight = ({
  bgColor,
  srcImg,
  title,
  titleColor,
  content,
  contentColor,
  btnContent,
  btnColor,
  display,
}) => {
  return (
    <div style={{ backgroundColor: bgColor }} className={`w-full py-8 my-2`}>
      <div className="mx-auto grid md:grid-cols-3 place-content-center">
        <div className="flex flex-col justify-center md:text-right md:col-span-2">
          <h1
            style={{ color: titleColor }}
            className="lg:text-[38px] text-3xl font-bold mx-[8vw] md:mx-16 lg:mr-[5vw] lg:ml-[10vw] underline text-shadow-sm"
          >
            {title}
          </h1>
          <p
            style={{ color: contentColor }}
            className="py-6 mx-[8vw] text-[18px] md:mx-16 lg:mr-[5vw] lg:ml-[10vw]"
          >
            {content}
          </p>

          <button
            style={{ backgroundColor: btnColor, display: display }}
            className="w-[150px] rounded-full font-medium lg:mt-6 place-self-end"
          >
            {btnContent}
          </button>
        </div>
        <img
          className={`w-2/3 max-h-80 lg:max-h-96 ml-[8vw] md:ml-0 md:my-4 md:col-span-1 mr-[8vw] md:mr-14 lg:mr-[10vw]`}
          src={srcImg}
          alt="/"
        />
      </div>
    </div>
  );
};

export default PictureContentRight;
