import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectLocation() {
  const [location, setLocation] = React.useState('');

  const handleChange = (event) => {
    setLocation(event.target.value);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
      <InputLabel id="demo-select-small-label">Location</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={location}
        label="Location"
        onChange={handleChange}
      >
        <MenuItem value={10}>San Luis Obispo</MenuItem>
        <MenuItem value={20}>Los Angeles</MenuItem>
        <MenuItem value={30}>Santa Babara</MenuItem>
      </Select>
    </FormControl>
  );
}
