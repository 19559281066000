import * as types from '../constants/equipment.constants';
import api from '../../api';
import { toast } from 'react-toastify';

const getAllEquipment = () => async (dispatch) => {
  dispatch({ type: types.GET_ALL_EQUIPMENT_REQUEST });
  try {
    const res = await api.get(`/equipments`);
    dispatch({
      type: types.GET_ALL_EQUIPMENT_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({ type: types.GET_ALL_EQUIPMENT_FAILURE, payload: error });
  }
};

const getSingleEquipment = (equipmentId) => async (dispatch) => {
  dispatch({ type: types.GET_SINGLE_EQUIPMENT_REQUEST, payload: null });
  try {
    const res = await api.get(`/equipments/${equipmentId}`);
    dispatch({
      type: types.GET_SINGLE_EQUIPMENT_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: types.GET_SINGLE_EQUIPMENT_FAILURE,
      payload: error,
    });
  }
};

const equipmentActions = {
  getAllEquipment,
  getSingleEquipment,
};
export default equipmentActions;
