import React from 'react';
import EventCard from './EventCard';

const EventList = () => {
  return (
    <div className="container mx-auto mt-10 pb-10">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-y-10 justify-items-center">
        <EventCard
          title="Vineyard Team"
          subheader="Vine-to-Wine Water Treatment Solutions"
          altImageText="Vine to Wine"
          image="https://www.vineyardteam.org/images/events/vine%20to%20wine%201.png"
          dateText="Apr 10, 2024"
          timeText="9:00am-11:00am"
          location="Lompoc, CA"
          mainText="Learn from a panel of experts about Reverse Osmosis as a water
              treatment option, their experience using it, the operational and
              technical processes to maintain RO systems, and cost analysis."
          signUpURL="https://vineyardteam.app.neoncrm.com/np/clients/vineyardteam/eventRegistration.jsp?event=197"
          learnMoreURL="https://vineyardteam.app.neoncrm.com/np/clients/vineyardteam/event.jsp?event=197&"
        />

        <EventCard
          title="Wild Farm Alliance"
          subheader="Tablas Creek Vineyard Field Day"
          altImageText="Vineyard Field Day"
          image="https://assets.nationbuilder.com/wildfarmalliance/pages/2010/features/original/Llamas-tablas-header.jpg?1710538713"
          dateText="Apr 25, 2024"
          timeText="8:00am-12:30pm"
          location="Paso Robles, CA"
          mainText="Join Tablas Creek Vineyard, Wild Farm Alliance (WFA), and The
          Vineyard Team out in the field! The event will feature a series of
          talks given by growers and agricultural professionals, a panel
          discussion, and a beneficial habitat walk."
          signUpURL="https://www.wildfarmalliance.org/tablas?utm_campaign=march_enews_2024&utm_medium=email&utm_source=wildfarmalliance"
          learnMoreURL="https://www.wildfarmalliance.org/tablas?utm_campaign=march_enews_2024&utm_medium=email&utm_source=wildfarmalliance"
        />

        <EventCard
          title="Elationscapes"
          subheader="Invitation to Permaculture Workshop"
          altImageText="Invitation to Permaculture Workshop"
          image="https://upload.wikimedia.org/wikipedia/commons/c/c8/Permaculture_garden.JPG"
          dateText="Apr 27, 2024"
          timeText="10:00am-1:00pm"
          location="San Miguel, CA"
          mainText="Join us for a tour of a non-profit grow-to-donate vegetable farm
          and fruit orchard in San Miguel. We will discuss the design of the
          farm, terracing, water system, crop layout and rotation, and
          Spring planting preparations."
          signUpURL="https://www.eventbrite.com/e/design-build-from-raw-land-to-small-farm-tickets-820397379647?aff=oddtdtcreator"
          learnMoreURL="https://slopermaculture.weebly.com/upcoming-events.html"
        />

        <EventCard
          title="City Farm SLO"
          subheader="3rd Annual Sheep Shearing Shindig"
          altImageText="3rd Annual Sheep Shearing Shindig"
          image="https://upload.wikimedia.org/wikipedia/commons/c/c8/Permaculture_garden.JPG"
          dateText="May 25, 2024"
          timeText="12:00pm-4:00pm"
          location="San Luis Obispo, CA"
          mainText="This agricultural festival celebrates the history and culture of
          the Navajo-Churro Sheep and is perfect for all ages! Live Sheep
          Shearing Demonstrations, wool washing, carding, spinning and
          felting demonstrations, kids' games & activities, live music and
          food."
          signUpURL="https://my805tix.com/e/sheep-shearing-shindig/tickets"
          learnMoreURL="https://my805tix.com/e/sheep-shearing-shindig"
        />

        <EventCard
          title="FARMstead ED"
          subheader="Open Farm Days"
          altImageText="FARMstead ED | Open Farm Days"
          image="https://farmsteaded.com/cdn/shop/t/3/assets/about.jpg?v=25157529146664922671615759848"
          dateText="June 21-23, 2024"
          timeText="all day"
          location="Paso Robles, CA"
          mainText="We invite you to TOUR. TASTE. LEARN. on the SLO Co Farm Trail."
          signUpURL="https://farmsteaded.com/"
          learnMoreURL="https://farmsteaded.com/"
        />

        <EventCard
          title="EcoFarm"
          subheader="45th Annual Conference"
          altImageText="EcoFarm 45th Annual Conference"
          image="https://images.squarespace-cdn.com/content/v1/64c2ee1fc10f2205e1d2508f/1708115281326-QZNMXTP73HNTUHF890VW/image-asset.jpeg"
          dateText="Jan 22-25, 2025"
          timeText="all day"
          location="Location TBA"
          mainText="EcoFarm advances ecological and just farming and food systems
          through learning, convening, celebrating, and advocating."
          signUpURL="https://www.eco-farm.org/"
          learnMoreURL="https://www.eco-farm.org/"
        />
      </div>
    </div>
  );
};

export default EventList;
