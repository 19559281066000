import React from 'react';
import Navbar from '../components/Navbar';
import ResourcesBG from '../assets/ResourcesBG.jpg';
import SpiralDiagram from '../assets/Spiral-Image.jpg';
import CarbonCycle from '../assets/carboncycle-usde.jpg';
import PictureContentLeft from '../components/PictureContentLeft';
import CarbonFarmSketch from '../assets/Fibershed-Carbon-Farming-Drawings.jpg';
import ContentBox from '../components/ContentBox';
import ResourcesLogo from '../assets/ResourcesLogo.svg';

const ResourcesPage = () => {
  return (
    <div className="bg-sky-200">
      <Navbar src={ResourcesBG} pageName={`Resources`} />
      <ContentBox
        bgColor="#A8C356"
        title="What is climate-smart agriculture?"
        titleColor="#FFFFFF"
        content={
        <div>
          <p>Climate-smart agriculture means adopting regenerative land management practices that increase 
          resilience against climate impacts &mdash; improving soil fertility and reducing water requirements. 
          This includes <strong>carbon farming</strong>, irrigation management, and aquifer recharge practices.</p>
          &nbsp;
          <p><img className={`w-[800px] mx-auto my-4`} src={SpiralDiagram} alt="The traditional process of project development for Conservation Districts." /></p>
          &nbsp;
          <p>
            <h1 className={`font-bold text-3xl`}>Benefits to the farmer</h1>
            Climate-smart agriculture can solve multiple problems farmers face simultaneously:
            <ul className={`list-inside indent-3 inset-1`}  style={{ listStyleType: 'square' }}>
              <li>It can dramatically decrease soil erosion and degradation.</li>
              <li>It can assist with water infiltration and retention (especially important in times of drought).</li>
              <li>It reduces the need for pesticides, herbicides and synthetic fertilizers over time, thereby reducing or eliminating the costs of those inputs.</li>
              <li>It reduces the amount of air pollution created by tillage.</li>
              <li>It reduces the amount of fossil fuels used and the associated costs by reducing tractor passes.</li>
            </ul></p>
          &nbsp;
          <p>
            Most farmers and ranchers report <strong>increased profits without reduction in yields</strong> once the initial transition is complete.  
          </p>
        </div>}
        contentColor="#FFFFFF"
        maxContentWidth = "max-w-7xl"
  
      />
      <ContentBox
        bgColor="#FFFFFF"
        title="Carbon farming"
        content={
          <div>
            <p>Carbon farming is a whole systems approach that works with nature to safely draw down CO2 from the atmosphere and help it accrue underground without excessive release back into the atmosphere. It is also called "regenerative" because, when done effectively, the same practices restore the natural biological functioning of the soil, improve soil health, and restore biodiversity and multiple interlocking environmental functions we depend on for food and water security and farm resiliency.</p>
            &nbsp;
            <p><img className={`w-[800px] mx-auto my-4`} src={CarbonFarmSketch} alt="The traditional process of project development for Conservation Districts." /></p>
            &nbsp;
            <p>
              The carbon farm plan was developed by <a href='https://www.carboncycle.org/' target='partners'>Carbon Cycle Institute</a>.
            </p>
          </div>
        }
        colorContent="#000000"
      />
    </div>
  );
};

export default ResourcesPage;
