import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SelectPrice from './SelectPrice';
import SelectLocation from './SelectLocation';
import SelectEquipment from './SelectEquipment';

const EquipmentFilter = () => {
  return (
    <div className="hidden mx-auto w-[910px] md:flex justify-center items-center rounded-full bg-white mt-10 flex-1">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <SelectPrice />
        <DatePicker
          label="Date Available"
          slotProps={{ textField: { size: 'small' } }}
        />
        <SelectLocation />
        <SelectEquipment />
        <button class="bg-slate-400 hover:bg-slate-500 text-white font-bold py-2 px-4 rounded-full">
          Find equipment
        </button>
      </LocalizationProvider>
    </div>
  );
};

export default EquipmentFilter;
