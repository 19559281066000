import React from 'react';
import Navbar from '../components/Navbar';
import ContactUsBG from '../assets/ContactUsBG.jpg';
import PictureContent from '../assets/PictureContent.svg';
import PictureContentRight from '../components/PictureContentRight';
import PictureContentLeft from '../components/PictureContentLeft';

const ContactUsPage = () => {
  return (
    <div className="bg-sky-200">
      <Navbar src={ContactUsBG} pageName={`About Us`} />
      <PictureContentRight
        bgColor="#FFEB83"
        srcImg={PictureContent}
        content={`Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum
       molestiae delectus culpa hic assumenda, voluptate reprehenderit
       dolore autem cum ullam sed odit perspiciatis. Doloribus quos velit,
       eveniet ex deserunt fuga?. 
       Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum
       molestiae delectus culpa hic assumenda, voluptate reprehenderit
       dolore autem cum ullam sed odit perspiciatis. Doloribus quos velit,
       eveniet ex deserunt fuga?`}
        contentColor="#000000"
      />
      <PictureContentLeft
        bgColor="#CF9A4B"
        srcImg={PictureContent}
        title=""
        content={`Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum
       molestiae delectus culpa hic assumenda, voluptate reprehenderit
       dolore autem cum ullam sed odit perspiciatis. Doloribus quos velit,
       eveniet ex deserunt fuga?. 
       Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum
       molestiae delectus culpa hic assumenda, voluptate reprehenderit
       dolore autem cum ullam sed odit perspiciatis. Doloribus quos velit,
       eveniet ex deserunt fuga?`}
        contentColor="#FFFFFF"
      />
    </div>
  );
};

export default ContactUsPage;
