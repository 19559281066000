import React from 'react';
import Navbar from '../components/Navbar';
import EventBG from '../assets/EventBG.jpg';
import EventList from '../components/EventList';

const EventPage = () => {
  return (
    <div className="bg-amber-100">
      <Navbar src={EventBG} pageName={`Events`} pageText={""}/>
      <EventList />
    </div>
  );
};

export default EventPage;
