export const GET_ALL_EQUIPMENT_REQUEST = 'EQUIPMENT.GET_ALL_EQUIPMENT_REQUEST';
export const GET_ALL_EQUIPMENT_SUCCESS = 'EQUIPMENT.GET_ALL_EQUIPMENT_SUCCESS';
export const GET_ALL_EQUIPMENT_FAILURE = 'EQUIPMENT.GET_ALL_EQUIPMENT_FAILURE';

export const CLEAR_SEARCHTERM = 'EQUIPMENT.CLEAR_SEARCHTERM';

export const GET_SINGLE_EQUIPMENT_REQUEST =
  'EQUIPMENT.GET_SINGLE_EQUIPMENT_REQUEST';
export const GET_SINGLE_EQUIPMENT_SUCCESS =
  'EQUIPMENT.GET_SINGLE_EQUIPMENT_SUCCESS';
export const GET_SINGLE_EQUIPMENT_FAILURE =
  'EQUIPMENT.GET_SINGLE_EQUIPMENT_FAILURE';

export const ADD_EQUIPMENT_REQUEST = 'EQUIPMENT.ADD_EQUIPMENT_REQUEST';
export const ADD_EQUIPMENT_SUCCESS = 'EQUIPMENT.ADD_EQUIPMENT_SUCCESS';
export const ADD_EQUIPMENT_FAILURE = 'EQUIPMENT.ADD_EQUIPMENT_FAILURE';

export const UPDATE_EQUIPMENT_REQUEST = 'EQUIPMENT.UPDATE_EQUIPMENT_REQUEST';
export const UPDATE_EQUIPMENT_SUCCESS = 'EQUIPMENT.UPDATE_EQUIPMENT_SUCCESS';
export const UPDATE_EQUIPMENT_FAILURE = 'EQUIPMENT.UPDATE_EQUIPMENT_FAILURE';

export const DELETE_EQUIPMENT_REQUEST = 'EQUIPMENT.DELETE_EQUIPMENT_REQUEST';
export const DELETE_EQUIPMENT_SUCCESS = 'EQUIPMENT.DELETE_EQUIPMENT_SUCCESS';
export const DELETE_EQUIPMENT_FAILURE = 'EQUIPMENT.DELETE_EQUIPMENT_FAILURE';
