export const LOGIN_REQUEST = 'AUTH.LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'AUTH.LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'AUTH.LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'AUTH.LOGOUT_REQUEST';

export const REGISTER_REQUEST = 'AUTH.REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'AUTH.REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'AUTH.REGISTER_FAILURE';

export const GET_CURRENT_USER_REQUEST = 'AUTH.GET_CURRENT_USER_REQUEST';
export const GET_CURRENT_USER_SUCCESS = 'AUTH.GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_FAILURE = 'AUTH.GET_CURRENT_USER_FAILURE';

export const GET_ALL_USER_REQUEST = 'AUTH.GET_ALL_USER_REQUEST';
export const GET_ALL_USER_SUCCESS = 'AUTH.GET_ALL_USER_SUCCESS';
export const GET_ALL_USER_FAILURE = 'AUTH.GET_CURRENT_USER_FAILURE';
