import * as React from 'react';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import { useNavigate } from 'react-router-dom';

export default function EquipmentCard({ equip }) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/farmshare/${equip._id}`);
  };
  return (
    <Card sx={{ width: 'auto', minWidth: '320px' }} onClick={handleClick}>
      <img
        //src="https://cdn.ironpla.net/i/13838/98/8f16b266-eb0c-43ad-aa31-6b5cdc159c4c-nowater.jpg"
        src={equip.images[0]}
        loading="lazy"
        alt=""
      />
      <CardContent orientation="horizontal" className="justify-between">
        <div>
          <Typography level="title-lg">{equip.name}</Typography>
          <Typography level="body-sm">{equip.location}</Typography>
        </div>

        <Typography fontSize="lg" fontWeight="lg">
          ${`${equip.rentalPrice}/Day`}
        </Typography>
      </CardContent>
    </Card>
  );
}
