import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API + '/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (request) => {
    console.log('Starting Request', request);
    const token = localStorage.getItem('token');
    if (token) {
      request.headers['Authorization'] = 'Bearer ' + token;
    }
    return request;
  },
  function (error) {
    console.log('REQUEST ERROR', error);
  },
);

api.interceptors.response.use(
  (response) => {
    console.log('Response:', response);
    if (response.data.data && response.data.data.accessToken) {
      api.defaults.headers.common['Authorization'] =
        'Bearer ' + response.data.data.accessToken;
      localStorage.setItem('token', response.data.data.accessToken);
    }
    return response;
  },
  function (error) {
    error = error.response.data;
    console.log('RESPONSE ERROR', error);
    let errorMsg = error.message || '';
    if (error.errors && error.errors.message)
      errorMsg = errorMsg + ': ' + error.errors.message;
    console.log('ERROR', errorMsg);
    return Promise.reject(error);
  },
);

export default api;
