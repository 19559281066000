import React from 'react';
import Navbar from '../components/Navbar';
import AchievementsBG from '../assets/AchievementsBG.jpg';
import PictureContentLeft from '../components/PictureContentLeft';
import PictureContent from '../assets/PictureContent.svg';
import ContentBox from '../components/ContentBox';

const AchievementsPage = () => {
  return (
    <div className="bg-sky-200">
      <Navbar src={AchievementsBG} pageName={`Achievements`} />
      <ContentBox
        bgColor="#A8C356"
        title="Who runs the Sustainable Land Initiative?"
        titleColor="#FFFFFF"
        content={`Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum
            molestiae delectus culpa hic assumenda, voluptate reprehenderit
            dolore autem cum ullam sed odit perspiciatis. Doloribus quos velit,
            eveniet ex deserunt fuga?. 
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum
            molestiae delectus culpa hic assumenda, voluptate reprehenderit
            dolore autem cum ullam sed odit perspiciatis. Doloribus quos velit,
            eveniet ex deserunt fuga?`}
        contentColor="#FFFFFF"
      />
      <PictureContentLeft
        bgColor="#FFFFFF"
        srcImg={PictureContent}
        content={`Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum
       molestiae delectus culpa hic assumenda, voluptate reprehenderit
       dolore autem cum ullam sed odit perspiciatis. Doloribus quos velit,
       eveniet ex deserunt fuga?. 
       Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum
       molestiae delectus culpa hic assumenda, voluptate reprehenderit
       dolore autem cum ullam sed odit perspiciatis. Doloribus quos velit,
       eveniet ex deserunt fuga?`}
        contentColor="#000000"
      />
    </div>
  );
};

export default AchievementsPage;
