import React, { Fragment, useEffect } from 'react';
import Navbar from '../components/Navbar';
import FarmshareBG from '../assets/FarmshareBG.jpg';
import DetailEquipment from '../components/DetailEquipment';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import equipmentActions from '../redux/actions/equipment.actions';

const DetailEquipmentPage = () => {
  const { equipmentId } = useParams();
  const dispatch = useDispatch();
  const equipment = useSelector((state) => state.equipments.selectedEquipment);

  useEffect(() => {
    dispatch(equipmentActions.getSingleEquipment(equipmentId));
  }, [equipmentId, dispatch]);

  return (
    <Fragment>
      {!equipment ? (
        <div>hehe</div>
      ) : (
        <div className="bg-amber-100">
          <Navbar src={FarmshareBG} pageName={`Farmshare`} />
          <DetailEquipment equip={equipment} />
        </div>
      )}
    </Fragment>
  );
};

export default DetailEquipmentPage;
