import * as types from '../constants/equipment.constants';

const initialState = {
  equipments: [],
  loading: false,
  totalPages: null,
  selectedEquipment: null,
};

const equipmentReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_ALL_EQUIPMENT_REQUEST:
    case types.GET_SINGLE_EQUIPMENT_REQUEST:
      return { ...state, loading: true };

    case types.GET_ALL_EQUIPMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        equipments: payload,
      };

    case types.GET_SINGLE_EQUIPMENT_SUCCESS:
      return { ...state, selectedEquipment: payload.equipment, loading: false };

    case types.GET_ALL_EQUIPMENT_FAILURE:
    case types.GET_SINGLE_EQUIPMENT_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default equipmentReducer;
