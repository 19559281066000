import * as types from '../constants/auth.constants';
import api from '../../api';
import { toast } from 'react-toastify';

/**Login with email password*/
const loginRequest =
  ({ email, password }) =>
  async (dispatch) => {
    dispatch({ type: types.LOGIN_REQUEST, payload: null });
    try {
      const res = await api.post('auth/login', { email, password });
      dispatch({ type: types.LOGIN_SUCCESS, payload: res.data.data });
      toast.success(`Welcome back!`);
    } catch (error) {
      dispatch({ type: types.LOGIN_FAILURE, payload: error });
      toast.error(error.errors.message);
    }
  };

/**Register new account*/
const register = (formData) => async (dispatch) => {
  dispatch({ type: types.REGISTER_REQUEST, payload: null });
  try {
    const res = await api.post('/users', formData);
    dispatch({ type: types.REGISTER_SUCCESS, payload: res.data.data });
    toast.success(`Thank you for your registration, ${formData.firstName}!`);
  } catch (error) {
    dispatch({ type: types.REGISTER_FAILURE, payload: error });
    toast.warn(error.errors.message);
  }
};

/**Get current user infomation*/
const getCurrentUser = () => async (dispatch) => {
  dispatch({ type: types.GET_CURRENT_USER_REQUEST, payload: null });
  try {
    const res = await api.get(`/myself`);
    dispatch({ type: types.GET_CURRENT_USER_SUCCESS, payload: res.data.data });
  } catch (error) {
    dispatch({ type: types.GET_CURRENT_USER_FAILURE, payload: error });
  }
};

/**log out*/
const logout = () => (dispatch) => {
  localStorage.removeItem('token');
  delete api.defaults.headers.common['Authorization'];
  dispatch({ type: types.LOGOUT_REQUEST, payload: null });
};

const authActions = {
  loginRequest,
  register,
  getCurrentUser,
  logout,
};
export default authActions;
