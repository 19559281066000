import React from 'react';
import Navbar from '../components/Navbar';
import RcdBG from '../assets/RcdBG.svg';
import PictureContentLeft from '../components/PictureContentLeft';
import ContentBox from '../components/ContentBox';
import ResourcesLogo from '../assets/ResourcesLogo.svg';

const RCDPage = () => {
  return (
    <div className="bg-sky-200">
      <Navbar
        src={RcdBG}
        pageName={`Upper Salinas-Las Tablas Resource Conservation District`}
      />
      <ContentBox
        bgColor="#A8C356"
        title="Who runs the Sustainable Land Initiative?"
        titleColor="#FFFFFF"
        content={`Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum
            molestiae delectus culpa hic assumenda, voluptate reprehenderit
            dolore autem cum ullam sed odit perspiciatis. Doloribus quos velit,
            eveniet ex deserunt fuga?. 
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum
            molestiae delectus culpa hic assumenda, voluptate reprehenderit
            dolore autem cum ullam sed odit perspiciatis. Doloribus quos velit,
            eveniet ex deserunt fuga?`}
        contentColor="#FFFFFF"
      />
      <PictureContentLeft
        bgColor="#FFFFFF"
        content={`Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum
            molestiae delectus culpa hic assumenda, voluptate reprehenderit
            dolore autem cum ullam sed odit perspiciatis. Doloribus quos velit,
            eveniet ex deserunt fuga?. 
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum
            molestiae delectus culpa hic assumenda, voluptate reprehenderit
            dolore autem cum ullam sed odit perspiciatis. Doloribus quos velit,
            eveniet ex deserunt fuga?`}
        colorContent="#000000"
        srcImg={ResourcesLogo}
      />
    </div>
  );
};

export default RCDPage;
