import React from 'react';

const PictureContentLeft = ({
  bgColor,
  srcImg,
  title,
  titleColor,
  content,
  contentColor,
  btnContent,
}) => {
  return (
    <div style={{ backgroundColor: bgColor }} className={`w-full py-5 my-2`}>
      <div className="grid md:grid-cols-3 ">
        <div className="col-span-1 ml-[8vw] md:ml-14 lg:ml-[9vw] md:pt-10">
          <img
            className={`max-h-[660px] object-contain justify-self-start`}
            src={srcImg}
            alt="/"
          />
        </div>
        <div className="flex flex-col justify-center col-span-2 md:text-left py-10 mr-[8vw] md:mr-16 lg:mr-[10vw] ml-[8vw] md:ml-0">
          <h1
            style={{ color: titleColor }}
            className="lg:text-[38px] text-3xl font-bold md:ml-10 underline text-shadow-sm"
          >
            {title}
          </h1>
          <p
            style={{ color: contentColor }}
            className="py-6 text-lg lg:text-[18px] md:ml-10 max-w-6xl"
          >
            {content}
          </p>
          <div className="md:text-[22px] ml-10 py-2">{btnContent}</div>
        </div>
      </div>
    </div>
  );
};

export default PictureContentLeft;
