import React from 'react';

const ContentBox = ({
  bgColor,
  title,
  titleColor,
  contentColor,
  content,
  textAlign = 'text-left',
  maxContentWidth = 'max-w-6xl',
  maxContentPad = '10',
}) => {
  return (
    <div style={{ backgroundColor: bgColor }} className={`py-4 my-4`}>
      <h1
        style={{ color: titleColor }}
        className={`underline text-3xl mx-[8vw] md:mx-16 lg:mx-[10vw] lg:text-[38px] font-bold font-Merriweather-Sans pt-5 md:pt-10 text-shadow-sm`}
      >
        {title}
      </h1>
      <p
        style={{ color: contentColor }}
        className={`${maxContentWidth} ${textAlign} text-md md:text-lg lg:text-[18px] mx-[8vw] md:mx-16 lg:mx-[10vw] py-5 lg:p-${maxContentPad}`}
      >
        {content}
      </p>
    </div>
  );
};

export default ContentBox;
