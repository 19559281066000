import React from 'react';
import Navbar from '../components/Navbar';
import AboutUsBG from '../assets/AboutUsBG.jpg';
import SliStructureDiagram from '../assets/SLI-structure-diagram.svg';
import ProjectProcessTraditionalDiagram from '../assets/project-process-traditional.svg';
import ProjectProcessSliDiagram from '../assets/project-process-SLI.svg';
import SLI_capacity from '../assets/SLI_capacity.svg';
import Aggregation from '../assets/Aggregation.svg';
import PictureContentRight from '../components/PictureContentRight';
import PictureContentLeft from '../components/PictureContentLeft';
import ContentBox from '../components/ContentBox';

const AboutUsPage = () => {
  return (
    <div>
      <Navbar
        src={AboutUsBG}
        pageName={`About SLI`}
        pageText={'What is the Sustainable Land Initiative?'}
      />
      <ContentBox
        title=""
        content={
          <div className="flex flex-col justify-center">
            <p>
              The Sustainable Land Initiative is a new way for Resource
              Conservation Districts to operate. SLI was developed by the&nbsp;
              <a
                href="https://www.us-ltrcd.org/"
                target="partner"
                className="text-lime-700 hover:text-emerald-700 hover:underline"
              >
                Upper Salinas-Las Tablas Resource Conservation District
              </a>
              &nbsp;in collaboration with the climate software company&nbsp;
              <a
                href="https://cbrain.com/climate"
                target="partner"
                className="text-lime-700 hover:text-emerald-700 hover:underline"
              >
                cBrain
              </a>
              &nbsp;and the Cal Poly&nbsp;
              <a
                href="https://climate.calpoly.edu/"
                target="partner"
                className="text-lime-700 hover:text-emerald-700 hover:underline"
              >
                Initiative for Climate Leadership and Resilience
              </a>
              .
            </p>
            <img
              className={`w-[800px] my-8`}
              src={SliStructureDiagram}
              alt="The collaborative structure of SLI."
            />
          </div>
        }
        bgColor={'#FFFFFF'}
        titleColor={'#2E7A32'}
        textAlign={'text-left'}
      />
      <ContentBox
        title="How It Works"
        content={
          <div>
            Resource Conservation Districts rely on grant cycles, so project
            implementation timelines can be very slow, often requiring 2-3 years
            for implementation.
            <img
              className={`w-[800px] mx-auto my-4`}
              src={ProjectProcessTraditionalDiagram}
              alt="The traditional process of project development for Conservation Districts."
            />
            <p>
              The Sustainable Land Initiative uses a single, cohesive, digital
              process to identify projects rapidly and see them through to
              funding and implementation at scale. This provides a more
              effective process for implemention on a timeline that fits the
              practical demands of producers. SLI transforms{' '}
              <strong>how</strong> RCDs operate but does not change{' '}
              <strong>what</strong> they do, and increasing speed and efficiency
              allows for a dramatic acceleration of the implementation and
              adoption of climate-smart practices.
            </p>
            <img
              className={`w-[800px] mx-auto my-4`}
              src={ProjectProcessSliDiagram}
              alt="The traditional process of project development for Conservation Districts."
            />
            <p>
              SLI increases the rate at which RCDs can move projects from
              intake, through assessment, analysis, and plan design, to
              implementation and reporting. Instead of the usual
              one-project-at-a-time, SLI brings implementation to multiple sites
              simultaneously. This{' '}
              <strong>
                reduces project timelines from 2-3 years to 6-8 weeks
              </strong>
              , and decreases RCD administrative burden{' '}
              <strong>from 2000 hours to 100 hours</strong>.
            </p>
            <p className="md:text-3xl sm:text-3xl text-2xl font-bold pt-5 text-violet-600">
              &rarr;This is an efficiency gain of 20X.
            </p>
          </div>
        }
        bgColor={'#CFE3F6'}
        titleColor={'#2E7A32'}
      />
      <PictureContentLeft
        bgColor="#CF9A4B"
        srcImg={Aggregation}
        titleColor={'#2E7A32'}
        title="Increasing equitable access for underserved producers"
        content={
          <div>
            During the first stage of the SLI process, all types of producers
            are aggregated to form an inventory of local needs. This helps
            ensure{' '}
            <strong>
              small-scale and underserved operations receive equitable access to
              resources
            </strong>
            . SLI allows family farms, low-income producers, and
            first-generation farmers to compete on the same level as
            industrialized outfits, by bundling projects and taking on the
            burden associated with grant development and administration. Small
            farming operations can now pursue opportunities that would
            previously have been unattainable. Bundling projects like this also
            allows SLI to pursue funding at scale, and reduce inter-farmer
            competition.
          </div>
        }
        contentColor="#000000"
      />
      <PictureContentRight
        bgColor="#FFFFFF"
        srcImg={SLI_capacity}
        titleColor={'#2E7A32'}
        title="SLI builds capacity for Conservation Districts"
        content={
          <div>
            <p>
              Digitalization allows SLI to increase efficiency and reduce
              administrative burden by using templates and automating routine
              tasks. For example, SLI reduces the time required to develop a
              Carbon Farm Plans <strong>from 6-12 months to 2-3 days</strong>.
            </p>
            &nbsp;
            <p>
              In place of common discontinuous management strategies (like Excel
              files and pieces of paper), SLI provides a centralized case
              management platform for tracking producer contacts from intake
              through implementation. SLI stores, sorts, and tracks all the data
              from intake, site visits, emails, planning, and funding and makes
              it accessible to the entire RCD project team. This reduces
              knowledge loss during employee turnover and allows information to
              be readily shared with other RCDs, if desired.
            </p>
          </div>
        }
        contentColor="#000000"
      />
      <ContentBox
        title="SLI provides a strategic approach"
        content={
          <div>
            The SLI platform provides capabilities that were previously
            inaccessible to RCDs, like&nbsp;
            <font className="italic">
              maintaining a database of potential local projects
            </font>
            . In addition to allowing RCDs to easily and effectively prioritize
            and target funding sources, this kind of information can be provided
            to decision-makers to guide local priorities for funding and other
            policy. This provides government with a valuable decision-support
            tool for attaining{' '}
            <a
              href="https://www.californianature.ca.gov/"
              target="30x30"
              className="text-lime-700 hover:text-emerald-700 hover:underline"
            >
              30x30 targets
            </a>
            .<p>&nbsp;</p>
          </div>
        }
        bgColor={'#CFE3F6'}
        titleColor={'#2E7A32'}
      />
      {/*<PictureContentRight
        title="Resources"
        titleColor="#2E7A32"
        content={`Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum
             molestiae delectus culpa hic assumenda, voluptate reprehenderit
             dolore autem cum ullam sed odit perspiciatis. Doloribus quos velit,
             eveniet ex deserunt fuga?. 
             Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum
             molestiae delectus culpa hic assumenda, voluptate reprehenderit
             dolore autem cum ullam sed odit perspiciatis. Doloribus quos velit,
             eveniet ex deserunt fuga?`}
        srcImg={PictureContent}
        btnContent="Find more"
        btnColor="#A8C356"
        display="inline-block"
      />*/}
    </div>
  );
};

export default AboutUsPage;
